<template>
  <section class="app-ecommerce-details">
    <b-alert
      variant="info"
      :show="pending"
    >
      <h4 class="alert-heading">        
        {{ t("buyinprocess") }}
      </h4>
    </b-alert>

    <b-row  align-h="center">
        <e-commerce-product v-for="(product,index) in fetchProducts"
        :key="index" :product="product" :pending="pending"/>
    </b-row>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import { ref } from '@vue/composition-api'

import {
  BCard, BCardBody, BRow, BCol, BContainer, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert,
} from 'bootstrap-vue'
import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProductsCustom from './ECommerceProductDetailsRelatedProductsCustom.vue'
import ECommerceProduct from './ECommerceProduct.vue'
import { useEcommerceUi } from '../useEcommerce'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const appLoading = document.getElementById('loading-bg');
const app = document.getElementById('app');
function toggleLoader(){
  app.style.display = app.style.display ? '' : 'none'
  appLoading.style.display = app.style.display ? '' : 'none'
}


export default {
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BContainer,
    ECommerceProduct,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProductsCustom,
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();
    const { t } = useI18nUtils();

    const fetchProducts = ref([]);
    const pending = ref(false)

    // Remote Data
    const fetchProduct = () => {
      // Get product  id from URL
      const { route } = useRouter()
      toggleLoader();
      store.dispatch('app-ecommerce/fetchProducts', {type:"tokens"})
        .then(response => {
          if(!response.data.enabled){
            router.push({path:"/"});
          }
          const products = response.data.products

          fetchProducts.value = products
          const ticketStatus = response.data.ticket_status;
          pending.value = ticketStatus === 'pending_contingency';
        })
        .catch(error => {
          console.log(error);
          
        }).finally(()=> toggleLoader() )
    }

    // UI
    const selectedColor = ref(null)

    fetchProduct()


    return {

      // Fetched Product
      pending,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
      fetchProducts,

      t
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
