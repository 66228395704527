<template>
    <section class="ecommerce-product">
        <b-col class="container-product">
            <b-row align-h="center">
                <b-col >
                <div class="text-center">
                    <h3 >{{ product.description }}</h3>
                
                    <div >{{ product.name }}</div>
                </div>
                </b-col>
            </b-row>

            <hr>

            <b-row align-h="center">
                <b-col >
                <div class="pricing-price text-center row-product">
                    <p ><span class="currency">{{ formatter(product.price) }}</span><span class="time"> MXN</span></p>
                </div>
                </b-col>
            </b-row>

            <hr>

            <b-row align-h="center">
                <b-col >
                <div class="pricing-price text-center row-product">
                    <p ><span class="currency">{{ product.metadata.tokens }}</span><span class="time"> {{t('invoice')}}</span></p>    
                </div>
                </b-col>
            </b-row>

            <b-row align-h="center">
                <b-col class="text-center row-product">
                    <div style="margin-left: 50px;" v-if="loading">
                        <b-icon 
                        icon="three-dots"
                        size="12"
                        animation="cylon"
                        />
                    </div>
                    <b-button v-else
                    variant="primary"
                    class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                    :disabled=pending
                    @click="addItem(product)"
                    >
                    <feather-icon
                        icon="ShoppingCartIcon"
                        class="mr-50"
                    />
                    <!-- <span>{{ product.isInCart ? t('view.in.cart') : t('add.to.cart') }}</span> -->
                    <span>{{ t('add.to.cart') }}</span>
                    </b-button>
                </b-col>
            </b-row>

            <!-- <b-row align-h="center">
                <b-col>
                <ul class="unstyled-list list-inline pl-1 text-center">
                <li
                    v-for="star in 5"
                    :key="star"
                    class="ratings-list-item mr-25"
                >
                    <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                    />
                </li>
                </ul>
                </b-col>
            </b-row> -->

            <b-row  align-h="center">
                <b-col class="row-product list-product">
                <div>
                    ✅ {{ t('inlcuding.taxes') }}
                </div>
                <div>
                    ✅ {{ t('no.expiration') }}
                </div>
                </b-col>
            </b-row>

            <div class="ecommerce-details-price mt-1">
                
            </div>
        </b-col>
    </section>
</template>

<script>
import {
 BRow, BCol,  BButton, BIcon
} from 'bootstrap-vue'
import { useEcommerceUi } from '../useEcommerce'
import { ref } from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BIcon
  },
  props: {
    product: Object,
    pending: Boolean
  },
  setup() {
    const { t } = useI18nUtils();
    let loading = ref(false)
    const { handleCartActionClick } = useEcommerceUi()

    const addItem = async (product) => {
        loading.value = true;
        try {
            await handleCartActionClick(product);
            product.isInCart = true
        } catch (error) {
            console.log(error);
        }finally{
            loading.value = false;
        }
    }
    return {
        addItem,
        loading,

        t
    }
  },
  methods:{
    formatter(price){
        const USDollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return USDollar.format(price);
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-product.scss";
</style>
